import axios from "axios";
import { get } from "svelte/store";
import { API_SERVER } from "../common/stores";
class RTCPeerPlayer {
    constructor(_channelId, _onMessage, _connectionStateFlag) {
        this.channel = null;
        this.RTCConnection = new RTCPeerConnection({
            iceServers: [
                {
                    urls: [
                        "stun:stun.l.google.com:19302",
                        "stun:stun1.l.google.com:19302",
                        "stun:stun2.l.google.com:19302",
                        "stun:stun3.l.google.com:19302",
                        "stun:stun4.l.google.com:19302",
                    ],
                },
            ],
        });
        this.isConnected = false;
        this.channelId = _channelId;
        this.connectionStateFlag = _connectionStateFlag;
        this.onMessage = _onMessage;
    }
    sendPing() {
        this.sendMessage("ping");
    }
    sendMessage(msg) {
        if (this.channel)
            this.channel.send(msg);
    }
    resetRTCConnection(callback = () => { }) {
        axios
            .get(`${window.location.toString().split("://")[0]}://${get(API_SERVER)}/.netlify/functions/resetConnection?channelId=${this.channelId}`)
            .then(() => {
            this.channel = null;
            this.isConnected = false;
            this.connectionStateFlag.set(false);
            this.RTCConnection = new RTCPeerConnection({
                iceServers: [
                    {
                        urls: [
                            "stun:stun.l.google.com:19302",
                            "stun:stun1.l.google.com:19302",
                            "stun:stun2.l.google.com:19302",
                            "stun:stun3.l.google.com:19302",
                            "stun:stun4.l.google.com:19302",
                        ],
                    },
                ],
            });
            axios
                .post(`${window.location.toString().split("://")[0]}://${get(API_SERVER)}/.netlify/functions/connectionState`, JSON.stringify({
                channelId: this.channelId,
                state: "not_connected",
            }))
                .then(callback);
        });
    }
    async connectP2PSession(successCallback = () => { }, failCallback = () => { }) {
        this.channel = this.RTCConnection.createDataChannel("stream-music");
        this.channel.onmessage = (event) => {
            if (event.data !== "ping")
                this.onMessage(event.data);
        };
        this.RTCConnection.onicecandidate = async (event) => {
            if (!event.candidate && this.RTCConnection.localDescription !== null) {
                axios
                    .post(`${window.location.toString().split("://")[0]}://${get(API_SERVER)}/.netlify/functions/offer`, JSON.stringify({
                    channelId: this.channelId,
                    offer: JSON.stringify(this.RTCConnection.localDescription),
                }))
                    .then(() => {
                    const timer = new Date().getTime();
                    let connectionFlag = false;
                    let resetFlag = false;
                    const listenAnswerInterval = setInterval(async () => {
                        // if (timer + 10000 < new Date().getTime() && !failFlag) {
                        //   clearInterval(listenAnswerInterval);
                        //   clearInterval(connectionCheckInterval);
                        //   this.resetRTCConnection();
                        //   failCallback();
                        //   failFlag = true;
                        //   return;
                        // }
                        console.log("hello world hahaha");
                        await axios
                            .get(`${window.location.toString().split("://")[0]}://${get(API_SERVER)}/.netlify/functions/connectionState?channelId=${this.channelId}`)
                            .then((res) => {
                            const { connectionState } = res.data;
                            console.log("hello world", connectionState);
                            if (connectionState === "created_answer") {
                                if (!connectionFlag) {
                                    connectionFlag = true;
                                    clearInterval(listenAnswerInterval);
                                    axios
                                        .get(`${window.location.toString().split("://")[0]}://${get(API_SERVER)}/.netlify/functions/answer?channelId=${this.channelId}`)
                                        .then(async (res) => {
                                        clearInterval(listenAnswerInterval);
                                        this.connectionStateFlag.set(true);
                                        const { answer } = res.data;
                                        await this.RTCConnection.setRemoteDescription(JSON.parse(answer))
                                            .then(successCallback)
                                            .then(() => {
                                            const connectionCheckInterval = setInterval(() => {
                                                try {
                                                    this.sendPing();
                                                    this.isConnected = true;
                                                    this.connectionStateFlag.set(true);
                                                }
                                                catch (err) {
                                                    if (this.isConnected) {
                                                        clearInterval(connectionCheckInterval);
                                                        this.resetRTCConnection();
                                                    }
                                                }
                                            }, 100);
                                        });
                                    });
                                }
                            }
                            else if (connectionState === "reset" && !resetFlag) {
                                resetFlag = true;
                                clearInterval(listenAnswerInterval);
                                // clearInterval(connectionCheckInterval);
                                this.resetRTCConnection();
                                failCallback();
                            }
                        });
                    }, 1000);
                });
            }
        };
        const offer = await this.RTCConnection.createOffer();
        await this.RTCConnection.setLocalDescription(offer);
    }
}
export { RTCPeerPlayer };
